.header, .header-space,
.footer, .footer-space {
  height: 12rem;
}
.header {
  position: fixed;
  top: 0;
}
.footer {
  position: fixed;
  bottom: 0;
}

.paper {
  width: 750px;
  font-family: chatthai;
}

.diagResult {
  font-family: sans-serif;
}

@font-face {
  font-family: chatthai;
  src: url(./asset/fonts/CSChatThaiUI.ttf);
}

@media print {
  .paper {
    width: 100%;
    page-break-after: always;
  }
}