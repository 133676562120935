body {
  margin: 0 !important;
  padding: 0;
  font-family: sans-serif;
}

html {
  height: 100vh;
  padding: 0;
  margin: 0;
}